import './Home.css';

import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Link, RouteComponentProps, useHistory, useLocation } from 'react-router-dom';

import { gql, useMutation, useQuery } from '@apollo/client';

import GenericErrorPage from '../components/GenericErrorPage';
import PageLoader from '../components/PageLoader';
import TenantLogo from '../components/TenantLogo';
import config from '../config';
import { useAuth, useQueryStringParsed, useTenantConfig } from '../hooks';
import Base from '../layouts/Base';
import { formatPhoneNumber } from '../utils';

interface GetMeData {
  me: {
    id: string;
    name: string;
    email: string;
    phoneNumber: string;
    firstName: string;
    tenant: string;
    demo: { jumpIntoWorkflow?: string };
  };
}

const GET_ME = gql`
  query GetMe {
    me {
      id
      name
      email
      phoneNumber
      firstName
      tenant
      demo {
        jumpIntoWorkflow
      }
    }
  }
`;

interface StartNewClaimData {
  startNewClaimWorkflow: {
    id: string;
  };
}

interface StartNewClaimVars {
  type?: string;
}

const START_NEW_CLAIM = gql`
  mutation StartNewClaim($type: WorkflowType) {
    startNewClaimWorkflow(type: $type) {
      id
    }
  }
`;

const Home: React.FC<RouteComponentProps> = ({ location }) => {
  const history = useHistory();
  const auth = useAuth();

  const queryString = useQueryStringParsed(location);
  const autoStartKey = queryString.get('start');
  const authenticatedDeepLinkToken = queryString.get('t');

  const {
    loading: loadingMe,
    error: errorMe,
    data: dataMe,
  } = useQuery<GetMeData>(GET_ME, {
    skip: !auth.isAuthenticated,
    onCompleted: ({ me }) => {
      window.analytics.identify(me.id, {
        name: me.name,
        email: me.email,
        phoneNumber: me.phoneNumber,
        tenant: me.tenant,
      });
    },
  });

  const [_startNewClaim, { loading: mutationLoading, error: mutationError }] =
    useMutation<StartNewClaimData, StartNewClaimVars>(START_NEW_CLAIM, {
      onCompleted: ({ startNewClaimWorkflow: { id } }) =>
        history.push({
          pathname: `/workflows/${id}`,
        }),
    });

  const startNewClaim = (payload?: any) => {
    if (
      !config.publicAccessMode &&
      auth.isAuthenticated &&
      !config.enableChunkedFrontendFlow
    ) {
      _startNewClaim(payload);
    } else {
      history.push({
        pathname: `/begin`,
        state: {
          startPayload: payload,
          name: dataMe?.me.name,
          autoJumpToPersonDetails: !!autoStartKey,
        },
      });
    }
  };

  useEffect(() => {
    window.analytics.track('Home Page Viewed', {
      authenticated: auth.isAuthenticated,
    });
  }, []);

  useEffect(() => {
    if (autoStartKey) {
      startNewClaim({ variables: { type: autoStartKey } });
    }
  }, [autoStartKey]);

  useEffect(() => {
    if (authenticatedDeepLinkToken) {
      history.replace({
        pathname: `/begin`,
        state: {
          authenticatedDeepLinkToken,
        },
      });
    }
  }, [authenticatedDeepLinkToken]);

  const tenantConfig = useTenantConfig();

  if (!tenantConfig || loadingMe) {
    return <PageLoader />;
  }

  const apolloError = mutationError || errorMe;
  if (apolloError) {
    return <GenericErrorPage error={apolloError} />;
  }

  let forceWorkflowType: string | undefined;
  let enablePortal = tenantConfig ? tenantConfig.enablePortal : false;
  let enableCATDemo = tenantConfig ? tenantConfig.enableCATDemo : false;
  let enableWinterStormDemo =
    dataMe?.me?.demo?.jumpIntoWorkflow === 'cat_property_demo_short' ||
    dataMe?.me?.demo?.jumpIntoWorkflow === 'cat_property_demo_long' ||
    dataMe?.me?.demo?.jumpIntoWorkflow === 'cat_auto_demo_long';
  let enableFireDemo =
    dataMe?.me?.demo?.jumpIntoWorkflow === 'cat_property_demo_fire';
  let hideMainFlow = false;

  if (
    dataMe?.me?.demo?.jumpIntoWorkflow === 'cat_demo' ||
    enableWinterStormDemo ||
    enableFireDemo
  ) {
    enablePortal = false;
    enableCATDemo = true;
    hideMainFlow = true;
  } else if (
    dataMe?.me?.demo?.jumpIntoWorkflow === 'home' ||
    tenantConfig.forceHomeFlow
  ) {
    enablePortal = false;
    enableCATDemo = false;
    forceWorkflowType = 'home';
  } else if (dataMe?.me?.demo?.jumpIntoWorkflow === 'workers_comp') {
    forceWorkflowType = 'workers_comp';
  } else if (dataMe?.me.demo?.jumpIntoWorkflow === 'plugin_demo') {
    forceWorkflowType = 'plugin_demo';
  }

  const hour = new Date().getHours();
  const greeting =
    hour < 5
      ? `Good morning${
          dataMe?.me.firstName ? `, ${dataMe?.me.firstName}` : ''
        } 🌙`
      : hour < 12
      ? `Good morning${
          dataMe?.me.firstName ? `, ${dataMe?.me.firstName}` : ''
        } 🌤️`
      : hour < 18
      ? `Good afternoon${
          dataMe?.me.firstName ? `, ${dataMe?.me.firstName}` : ''
        } 🌤️`
      : `Good evening${
          dataMe?.me.firstName ? `, ${dataMe?.me.firstName}` : ''
        } 🌙`;

  const tenant =
    config.multiTenantSelectedTenant ||
    dataMe?.me.tenant ||
    tenantConfig?.tenant ||
    '';

  return (
    <Base
      loading={mutationLoading}
      tenant={tenant}
      compact
      className="Home"
      containerClassName="HomeInner"
      expandedLanguageSwitcher
    >
      <TenantLogo
        tenant={tenant}
        size="large"
        className={'block mx-auto mb-8'}
      />
      <h2 className="text-center text-3xl font-bold text-cool-gray-600 leading-8 mb-3">
        {greeting}
      </h2>
      {tenantConfig.disableNewClaimFiling ? (
        <>
          <h2 className="mt-4 text-center text-lg text-cool-gray-500">
            The online system is not currently available. Please give us a call
            at{' '}
            <a
              className="font-semibold text-blue-600 hover:text-blue-500 whitespace-no-wrap"
              href={`tel:${tenantConfig.phoneNumber}`}
            >
              {formatPhoneNumber(tenantConfig.phoneNumber)}
            </a>
            .
          </h2>
        </>
      ) : tenantConfig.investigationOnlyMode ? (
        <>
          <h2 className="mt-4 text-center text-lg text-cool-gray-500">
            Please use the link originally sent to you in order to return to the
            investigation portal.
          </h2>
        </>
      ) : (
        <>
          <h2 className="text-center text-lg text-cool-gray-500">
            How can we help you today?
          </h2>
          <div className="flex justify-center mt-5 flex-col">
            {enableCATDemo ? (
              <div
                className={classNames(
                  'rounded-md mb-3',
                  dataMe?.me.tenant === 'tesla' ? '' : 'p-5 bg-cool-gray-200',
                )}
              >
                <div
                  className={classNames(
                    'mb-2 font-medium',
                    dataMe?.me.tenant === 'tesla'
                      ? 'text-white'
                      : 'text-cool-gray-500',
                  )}
                >
                  Affected by{' '}
                  {enableWinterStormDemo
                    ? `Winter Storm Uri`
                    : enableFireDemo
                    ? 'the Tahoe Wildfire'
                    : `Hurricane Richard`}
                  ?
                </div>
                <button
                  onClick={() =>
                    startNewClaim({
                      variables: {
                        type:
                          enableWinterStormDemo || enableFireDemo
                            ? dataMe?.me?.demo?.jumpIntoWorkflow
                            : 'cat_demo',
                      },
                    })
                  }
                  className="w-full m-0 btn btn-magic"
                >
                  File a{' '}
                  {enableFireDemo
                    ? 'wildfire-related claim 🔥'
                    : 'storm-related claim ⛈'}
                </button>
              </div>
            ) : null}
            {!hideMainFlow ? (
              <button
                onClick={
                  config.demoPreviewModeTenant
                    ? undefined
                    : () =>
                        startNewClaim(
                          forceWorkflowType
                            ? { variables: { type: forceWorkflowType } }
                            : undefined,
                        )
                }
                className={classNames(
                  'w-full m-0 btn',
                  enableCATDemo ? '' : 'btn-magic',
                  config.demoPreviewModeTenant ? 'btn-disabled' : '',
                )}
                disabled={!!config.demoPreviewModeTenant}
              >
                {config.demoPreviewModeTenant
                  ? 'Contact sales to activate FNOL portal'
                  : enableCATDemo
                  ? 'File a different type of claim'
                  : `File a new ${
                      forceWorkflowType === 'workers_comp'
                        ? 'workers compensation '
                        : forceWorkflowType === 'home'
                        ? 'property '
                        : ''
                    }claim`}
              </button>
            ) : null}
            {enablePortal ? (
              <button
                onClick={() => history.push('/policy')}
                className="w-full m-0 btn mt-3"
              >
                Manage my policy
              </button>
            ) : null}
            {enablePortal ? (
              <button
                onClick={() => history.push('/cards')}
                className="w-full m-0 btn btn-subtle mt-3"
              >
                View insurance card
              </button>
            ) : null}
          </div>
        </>
      )}
      {!config.publicAccessMode && !process.env.REACT_APP_LOCALIZE_KEY ? (
        <div className="mt-5 text-cool-gray-500 text-sm">
          Prefer the phone? Give us a call at{' '}
          <a className="font-semibold" href="tel:18002073618">
            800.207.3616
          </a>
          .
        </div>
      ) : null}
    </Base>
  );
};

export default Home;
