import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import GenericErrorPage from '../components/GenericErrorPage';
import PageLoader from '../components/PageLoader';
import DamagePicker from '../components/VehicleDamagePicker';

interface GetClaimData {
  claim: {
    raw: {
      vehicleDamageMesh: string;
    };
    user: {
      tenant: string;
    };
  };
}

interface GetClaimVars {
  claim: string;
}

const GET_CLAIM = gql`
  query Claim($claim: ID!) {
    claim(id: $claim) {
      raw
      user {
        tenant
      }
    }
  }
`;

interface DamageRendererParams {
  claimId: string;
}

const DamageRenderer: React.FC<RouteComponentProps<DamageRendererParams>> = ({
  match: {
    params: { claimId },
  },
}) => {
  let { loading, data, error } = useQuery<GetClaimData, GetClaimVars>(
    GET_CLAIM,
    {
      variables: { claim: claimId },
    },
  );

  if (loading) {
    return <PageLoader />;
  }

  if (error) {
    return <GenericErrorPage error={error} />;
  }

  if (!data?.claim.raw.vehicleDamageMesh) {
    return <div />;
  }

  return (
    <div className="container-fluid">
      <DamagePicker
        initialValue={data.claim.raw.vehicleDamageMesh}
        viewOnly
        onChange={() => {}}
        isTesla={data.claim?.user?.tenant === 'tesla'}
      />
    </div>
  );
};

export default DamageRenderer;
