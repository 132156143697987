import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import CollisionReconstruction from '../components/CollisionReconstruction';

interface CollisionReconstructionRendererParams {}

const CollisionReconstructionRendererPage: React.FC<
  RouteComponentProps<CollisionReconstructionRendererParams>
> = ({ location }) => {
  useEffect(() => {
    document.addEventListener(
      'collision-recording-start',
      () => {
        console.log('* collision-recording-start');
      },
      { once: true },
    );
    document.addEventListener(
      'collision-recording-end',
      () => {
        console.log('* collision-recording-end');
      },
      { once: true },
    );
  }, []);

  const collisionLocation = JSON.parse('{"id":"x","lat":0,"lng":0}');

  const params = new URLSearchParams(location.search);
  const collisionReconstructionData = JSON.parse(params.get('data') || '');

  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <CollisionReconstruction
        collisionLocation={collisionLocation}
        initialData={collisionReconstructionData}
        selfLabel={'User vehicle'}
        otherPartyLabel={'Other vehicle'}
        playbackOnly
        responsive
        recordingMode
      />
    </div>
  );
};

export default CollisionReconstructionRendererPage;
